import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, CardContent, Collapse, Grid, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    servicesCardsContainer: {

    },

    root: {
        maxWidth: 345,
        margin: theme.spacing(1),
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
    },
    rootActive: {
        maxWidth: 345,
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    media: {
        width: "30%",
    },
}))

const ServiceCards = props => {

    const classes = useStyles(props);

    const { servicedata } = props;
    const [open, setOpen] = React.useState(false);

    const handleServiceClick = () => {
        console.log(open);
        setOpen(!open);
    };
    const cardClass = open ? classes.rootActive : classes.root;
    return (
        <Grid xs={6} md={4} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <Card className={cardClass}>
                <CardActionArea>
                    <CardContent >
                        <Grid container direction="row" justify="center" alignItems="center" >
                            <Grid item xs={12} md={12} style={{ padding: 20 }}  >
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <img src={servicedata.icon} height={50} width={50} /><br />
                                </Grid>
                            </Grid>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Typography variant="body2" color={open ? "primary" : "textSecondary"} component="p" align="center" style={{ minHeight: 50, }}>
                                    {servicedata.service}
                                </Typography>
                            </Collapse>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

ServiceCards.propTypes = {

}

export default ServiceCards

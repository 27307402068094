import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    headingCaption: {
        color: '#AAAAAA',
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth: 4,
        borderLeftStyle: 'solid',
        fontWeight: 'bolder'
    },
}));

const CustomHeading = props => {

    const classes = useStyles(props);
    const {text} = props

    return (
        <Typography className={classes.headingCaption}>
            &nbsp;&nbsp;{text}
        </Typography>
    )
}

CustomHeading.propTypes = {

}

export default CustomHeading

import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    clientCardsContainer: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10)
    }
}));

const ClientsPageContainer = props => {
    const classes = useStyles(props);

    const client_images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <Grid container direction="row" justify="center" alignItems="center" >
            <Grid item xs={12} md={12}>
                <img src="images/pages/clients/background.png " width="100%" />
            </Grid>
            <Grid xs={10} md={10}>
                <Typography variant="h4" color="primary" style={{ padding: 20, textAlign: 'center' }}><b>Our Clients</b></Typography>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.clientCardsContainer}>
                    {
                        client_images.map(id => {
                            const imageURL = `images/clients/${id}.png`;
                            return <Grid xs={6} md={2}><img src={imageURL} width="100%" /></Grid>

                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

ClientsPageContainer.propTypes = {

}

export default ClientsPageContainer

import React from 'react'
import PropTypes from 'prop-types'
import { Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    iconContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginLeft: theme.spacing(1)
        // backgroundColor: theme.palette.primary.light,
    },
    card: {
        marginTop: -theme.spacing(10),
        padding: theme.spacing(2),
        borderRadius: 10,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
    },
    divider: {
        width: 4,
        backgroundColor: theme.palette.primary.main,
    },
    test: {
        backgroundColor: theme.palette.primary.light,
    },
    styledAnchor: {
        textDecoration: 'none',
        color: theme.palette.text.secondary,
    }
}));

const ContactCardItem = props => {

    const classes = useStyles(props);
    const { fieldName, icon, fieldValue, hyperlink } = props;
    const iconURl = `images/icons/${icon}.svg`;

    return (
        <Grid item xs={12} md={4} lg={4}>
            <Card className={classes.card} >
                <Grid container direction="row">
                    <Grid item xs={3}>
                        <Grid container direction="column" className={classes.iconContainer} justify="center" alignItems="center">
                            <Grid item xs={12}>
                                <img src={iconURl} alt={icon} width={60} />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {fieldName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <Divider orientation='vertical' variant="middle" className={classes.divider} />
                    </Grid>
                    <Grid item xs={7} className={classes.iconContainer}>
                        <Grid container direction="column" justify="center" >
                            <Grid item style={{marginTop: 20}}>
                                <a href={hyperlink} className={classes.styledAnchor}>
                                    <Typography style={{fontWeight: 'bolder'}}>{fieldValue}</Typography>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

ContactCardItem.propTypes = {

}

export default ContactCardItem

import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import ClientsCarousal from '../components/ClientsCarousal';
import CustomHeading from '../components/CustomHeading';
import CustomSubHeading from '../components/CustomSubHeading';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(25)
    },
    clientSectionheaders: {
        paddingBottom: theme.spacing(5)
    }

}));

const ClientsContainer = props => {
    const classes = useStyles(props);

    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={11} md={10} className={classes.clientSectionheaders}>
                <CustomHeading text="OUR CLIENTS" />
                <br />
                <CustomSubHeading text="Our happy Customers" />
                <br />
            </Grid>
            <ClientsCarousal />
        </Grid>
    )
}

ClientsContainer.propTypes = {

}

export default ClientsContainer

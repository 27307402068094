import React, { Component, useEffect, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Grid, makeStyles } from "@material-ui/core";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
    arrowKeys: {
        fontSize: 150,
        color: theme.palette.primary.main,
        opacity: 0.2
    }
}));


const WorkCarousel = (props) => {

    const classes = useStyles(props);

    const [goToSlide, setGoToSlide] = useState(0);
    const [slides, setSlides] = useState([]);


    const navigate = type => {
        let newGoToSlide = goToSlide;
        if (type === '+') {
            newGoToSlide = newGoToSlide + 1;
        } else {
            newGoToSlide = newGoToSlide - 1;
        }
        setGoToSlide(newGoToSlide);
    }

    useEffect(() => {
        const cards = [
            1, 2, 3, 4, 5, 7
        ].map((fileName, index) => {
            const imageURL = `images/projects/${fileName}.jpg`;
            return { key: fileName, content: <img src={imageURL} alt={index} width="100%" style={{ borderRadius: 10 }} />, onClick: () => setGoToSlide(index) };
        });
        setSlides(cards);
    }, [])

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={10}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={1}>
                        <KeyboardArrowLeftIcon className={classes.arrowKeys} onClick={() => navigate("-")} />
                    </Grid>
                    <Grid item xs={10}>
                        <div style={{ width: "80%", height: 350, margin: "0 auto" }}>
                            <Carousel
                                slides={slides}
                                goToSlide={goToSlide}
                                offsetRadius={2}
                                animationConfig={config.gentle}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <KeyboardArrowRightIcon className={classes.arrowKeys} onClick={() => navigate("+")} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default WorkCarousel

import React from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    Grid,
    makeStyles,
    Typography,
    CardActionArea,
    Collapse,
    CardContent
} from '@material-ui/core'
import ServiceCards from '../components/ServiceCards'

const useStyles = makeStyles((theme) => ({
    servicesCardsContainer: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        minHeight: theme.spacing(70)
    },

    root: {
        maxWidth: 345,
        minHeight: 250,
        margin: theme.spacing(1),
        backgroundColor: '#fff'
    },
    media: {
        width: "30%",
    },
}))

const ServicesPageContainer = props => {

    const classes = useStyles(props);

    const services = [
        { icon: "images/pages/services/AirConditioner.svg", service: "Air-conditioning, Ventilations & Air filtration systems installation & maintenance", description: "" },
        { icon: "images/pages/services/ElectoMechanical.svg", service: "Electromechanical equipment installation & maintenance", description: "" },
        { icon: "images/pages/services/Sabitary.svg", service: "Plumbing & Sanitary contracting", description: "" },
        { icon: "images/pages/services/Tiling.svg", service: "Floor & Wall Tiling Works", description: "" },
        { icon: "images/pages/services/Painting.svg", service: "Painting Contracting", description: "" },
        { icon: "images/pages/services/Carpentry.svg", service: "Carpentry & Flooring Contracting", description: "" },
        { icon: "images/pages/services/WallpaperFixing.svg", service: "Wallpaper Fixing", description: "" },
        { icon: "images/pages/services/FalseCealing.svg", service: "Partitions & False Ceiling Contracting", description: "" },
        { icon: "images/pages/services/Cleaning.svg", service: "Building Cleaning Services", description: "" },
    ]
    return (
        <Grid container direction="row" justify="center" alignItems="center" >
            <Grid item xs={12} md={12}>
                <img src="images/pages/services/background.jpg " width="100%" />
            </Grid>
            <Grid xs={10} md={8}>
                <Typography variant="h4" color="primary" style={{ padding: 20, textAlign: 'center' }}><b>Our Services</b></Typography>

                <Grid container direction="row" justify="center" alignItems="center" className={classes.servicesCardsContainer} spacing={2}>
                    {
                        services.map((servicedata, index) => {
                            return (<ServiceCards servicedata={servicedata} key={index.toString()} />)
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

ServicesPageContainer.propTypes = {

}

export default ServicesPageContainer

import React, { useState } from 'react';
import { Paper, Button, Grid } from '@material-ui/core'

import Carousel, { slidesToShowPlugin, arrowsPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const ClientsCarousal = props => {

    const client_images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const cutomLeftButton = (
        <Button color="primary" variant="outlined" size="large" style={{ borderRadius: 100 }}><ArrowBackIcon /></Button>
    );
    const cutomRighttButton = (
        <Button color="primary" variant="outlined" size="large" style={{ borderRadius: 100 }}><ArrowForwardIcon /></Button>
    );

    const arraowPluginConfig = {
        resolve: arrowsPlugin,
        options: {
            arrowLeft: cutomLeftButton,
            arrowLeftDisabled: cutomLeftButton,
            arrowRight: cutomRighttButton,
            arrowRightDisabled: cutomRighttButton,
            addArrowClickHandler: true,
        }
    };

    const autoPlayConfigs = {
        resolve: autoplayPlugin,
        options: {
            interval: 4000,
        }
    };
    return (

        <Grid xs={11} md={10}>

            <Carousel
                plugins={[
                    // 'arrows',
                    'infinite',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: 7
                        }
                    },
                    { ...arraowPluginConfig },
                    { ...autoPlayConfigs }
                ]}
                breakpoints={{
                    640: {
                        plugins: [
                            // 'arrows',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 1
                                }
                            },
                            { ...arraowPluginConfig },
                            { ...autoPlayConfigs }
                        ]
                    },
                    900: {
                        plugins: [
                            // 'arrows',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 3
                                }
                            },
                            { ...arraowPluginConfig },
                            { ...autoPlayConfigs }
                        ]
                    },
                    1024: {
                        plugins: [
                            // 'arrows',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 4
                                }
                            },
                            { ...arraowPluginConfig },
                            { ...autoPlayConfigs }
                        ]
                    },
                }}
            >

                {
                    client_images.map(id => {
                        const imageURL = `images/clients/${id}.png`;
                        return <img src={imageURL} />

                    })
                }
            </Carousel>
        </Grid>
    )
}
export default ClientsCarousal

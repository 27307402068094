import React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    navbarButton: {
        minWidth: theme.spacing(12),
        color: props => props.isSelected ? theme.palette.primary.main : theme.palette.secondary.light,
        fontWeight: 'bold'
    },
    iconButton: {
        color: theme.palette.secondary.contrastText,
    },
    grow: {
        flexGrow: 1,
    },
    icons: {
        width: theme.spacing(3),
    }
}));

const NavbarButtons = props => {
    const classes = useStyles(props);
    const { id, text, isSelected, setSelectedMenu, isMobile } = props
    const iconURL = isSelected ? `images/mobile_navs/active/${id}.png` : `images/mobile_navs/inactive/${id}.png`;
    return (
        isMobile ? (
            <>
                <IconButton edge="start" className={classes.iconButton} aria-label="open drawer" onClick={() => { setSelectedMenu(id) }}>
                    <img src={iconURL} className={classes.icons} />
                </IconButton> <div className={classes.grow} />
            </>) : (
            <Button size="small" className={classes.navbarButton} onClick={() => setSelectedMenu(id)}>{text}</Button>
        )
    )
}

NavbarButtons.propTypes = {

}

export default NavbarButtons


import React, { useState } from 'react'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import BannerContainer from './containers/BannerContainer';
import { Grid, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ContactContainer from './containers/ContactContainer';
import AboutUsContainer from './containers/AboutUsContainer';
import ServicesContainer from './containers/ServicesContainer';
import ClientsContainer from './containers/ClientsContainer';
import WorksContainer from './containers/WorksContainer';
import FooterContainer from './containers/FooterContainer';
import CustomNavbarSmall from './components/CustomNavbarSmall';
import CustomNavbarLarge from './components/CustomNavbarLarge';
import GetInTouchModal from './components/GetInTouchModal';
import AboutPageContainer from './containers/page/about/containers/AboutPageContainer';
import ClientsPageContainer from './containers/page/clients/containers/ClientsPageContainer';
import ServicesPageContainer from './containers/page/services/containers/ServicesPageContainer';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff823e',
      main: '#FF630E',
      dark: '#b24509',
      contrastText: '#000000',
    },
    secondary: {
      light: '#535353',
      main: '#292929',
      dark: '#1c1c1c',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
    ].join(','),
  },
});

const useStyles = makeStyles((theme) => ({
  appContainer: {
    overflow: 'hidden'
    // shadow: 'none',
    // padding: 10,
    // margin: -10,
  },
}));

const menuItems = [
  { id: 'home', text: 'Home' },
  { id: 'about', text: 'About' },
  { id: 'services', text: 'Services' },
  { id: 'clients', text: 'Clients' },
  { id: 'projects', text: 'Projects' },
]

const HomeContents = props => {
  const { isMobile, toggleContactModal, setSelectedMenu } = props;
  return (
    <>
      <BannerContainer isMobile={isMobile} toggleContactModal={toggleContactModal} setSelectedMenu={setSelectedMenu} />
      <ContactContainer isMobile={isMobile} />
      <AboutUsContainer isMobile={isMobile} setSelectedMenu={setSelectedMenu} />
      <ServicesContainer isMobile={isMobile} />
      <ClientsContainer isMobile={isMobile} />
      <WorksContainer isMobile={isMobile} />
      <FooterContainer isMobile={isMobile} />
    </>
  )
}

const AboutContents = props => {
  const { isMobile } = props;
  return (
    <>
      <AboutPageContainer isMobile={isMobile} />
      <FooterContainer isMobile={isMobile} />
    </>
  )
}

const ServicesContents = props => {
  const { isMobile } = props;
  return (
    <>
      <ServicesPageContainer isMobile={isMobile} />
      <FooterContainer isMobile={isMobile} />
    </>
  )
}

const ClientsContents = props => {
  const { isMobile } = props;
  return (
    <>
      <ClientsPageContainer isMobile={isMobile} />
      <FooterContainer isMobile={isMobile} />
    </>
  )
}

const ProjectsContents = props => {
  const { isMobile } = props;
  return (
    <>
      <FooterContainer isMobile={isMobile} />
    </>
  )
}

const App = () => {

  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedMenu, setSelectedMenu] = useState("home")
  const [getInTouchModal, setGetInTouchModal] = useState(false)

  const toggleContactModal = () => setGetInTouchModal(!getInTouchModal);

  console.log(`mobile : ${isMobile} selected: ${selectedMenu}`);
  return (
    <ThemeProvider theme={theme}>
      <CustomNavbarLarge isMobile={isMobile} menuItems={menuItems} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} toggleContactModal={toggleContactModal} />

      <Grid container className={classes.appContainer} direction="column" spacing={0}>
        {selectedMenu === 'home' && <HomeContents isMobile={isMobile} toggleContactModal={toggleContactModal} setSelectedMenu={setSelectedMenu} />}
        {selectedMenu === 'about' && <AboutContents isMobile={isMobile} />}
        {selectedMenu === 'services' && <ServicesContents isMobile={isMobile} />}
        {selectedMenu === 'clients' && <ClientsContents isMobile={isMobile} />}
        {selectedMenu === 'projects' && <ProjectsContents isMobile={isMobile} />}
      </Grid>
      <CustomNavbarSmall isMobile={isMobile} menuItems={menuItems} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} toggleContactModal={toggleContactModal} />

      <GetInTouchModal isMobile={isMobile} getInTouchModal={getInTouchModal} setGetInTouchModal={setGetInTouchModal} />
    </ThemeProvider>
  )
}

export default App


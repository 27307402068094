import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Grid, IconButton, makeStyles, Toolbar } from '@material-ui/core'
import NavbarButtons from './NavbarButtons';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: theme.palette.secondary.contrastText,
    },
    appBar: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
    },
    grow: {
        flexGrow: 1,
    },
    icons: {
        width: theme.spacing(3),
    }
}));


const CustomNavbarSmall = props => {
    const classes = useStyles();
    const { isMobile, menuItems, selectedMenu, setSelectedMenu, toggleContactModal } = props;
    return (
        isMobile ? (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Grid container direction="row" alignItems="center" justify="center">
                        {
                            menuItems.map(menuItem => (
                                <NavbarButtons
                                    isMobile={isMobile}
                                    key={menuItem.id}
                                    id={menuItem.id}
                                    text={menuItem.text}
                                    isSelected={selectedMenu === menuItem.id}
                                    setSelectedMenu={setSelectedMenu}
                                />
                            ))
                        }
                        <IconButton edge="end" className={classes.iconButton} aria-label="open drawer" onClick={() => toggleContactModal()}>
                            <img src="images/mobile_navs/active/contact.png" className={classes.icons} />
                        </IconButton>
                    </Grid>
                </Toolbar>
            </AppBar>
        ) : (
            <></>
        )
    )
}

CustomNavbarSmall.propTypes = {

}

export default CustomNavbarSmall

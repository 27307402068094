import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, makeStyles, Typography } from '@material-ui/core'
import CallToActionButton from '../components/CallToActionButton';


const useStyles = makeStyles((theme) => ({
    bannerContainer: {
        // minHeight: '100%',
        backgroundColor: '#a5a5a5'
    },
    bannerContent: {
        position: 'absolute',
        left: 0, right: 0, top: '30%',
        zIndex: 1,
        boxShadow: 'none',
        backgroundColor: 'rgba(255,255,255,0)',
    },
    headingCaption: {
        color: '#292929',
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth: 4,
        borderLeftStyle: 'solid',
        fontWeight: 'bolder'
    },
    bannerMainTest: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    mobileBannerContainer: {

    }
}));

const BannerContainer = props => {
    const classes = useStyles(props);
    const { isMobile, toggleContactModal, setSelectedMenu } = props;
    const selectAboutMenu = () => {
        setSelectedMenu('about');
    }
    return (
        isMobile ? (
            <Grid container className={classes.mobileBannerContainer}>
                <img src="images/banner_mob.png" width="100%" z-index="-1" style={{ boxShadow: '0 0 20px 20px red inset' }} />
                <Card className={classes.bannerContent} >
                    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
                        <Grid item xs={7}>
                            <Typography className={classes.headingCaption}>
                                &nbsp;&nbsp;SNGC
                            </Typography>
                            <Typography variant="h4" className={classes.bannerMainTest}>
                                Maintenance Services <br />
                                You Can Trust
                            </Typography>

                        </Grid>
                        <Grid item xs={11}  >
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <CallToActionButton actionType="secondary" text="LEARN MORE" onClick={selectAboutMenu} />
                                &nbsp;<CallToActionButton actionType="primary" text="GET IN TOUCH" onClick={toggleContactModal} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        ) : (
            <Grid container className={classes.bannerContainer}>
                <img src="images/banner.png" width="100%" z-index="-1" />
                <Card className={classes.bannerContent} >
                    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
                        <Grid item xs={7}>
                            <Typography className={classes.headingCaption}>
                                &nbsp;&nbsp;SNGC
                            </Typography>
                            <Typography variant="h2" className={classes.bannerMainTest}>
                                Maintenance Services <br />
                                You Can Trust
                            </Typography>
                            <CallToActionButton actionType="secondary" text="LEARN MORE" onClick={selectAboutMenu} />
                            &nbsp;&nbsp;<CallToActionButton actionType="primary" text="GET IN TOUCH" onClick={toggleContactModal} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid >
        )
    )
}

BannerContainer.propTypes = {

}

export default BannerContainer

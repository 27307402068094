import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CustomHeading from './CustomHeading';
import { Button, Grid } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    modalButtons: {
        borderRadius: 0,
        color: theme.palette.secondary.contrastText,
    }
}));

const GetInTouchModal = props => {
    const classes = useStyles(props)
    const { getInTouchModal, setGetInTouchModal } = props;

    const handleClickOpen = () => {
        setGetInTouchModal(true);
    };

    const handleClose = (value) => {
        setGetInTouchModal(false);
    };

    return (

        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={getInTouchModal}>
            <DialogTitle id="simple-dialog-title"><CustomHeading text="Get In Touch With Us" /></DialogTitle>
            <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
                <Grid item xs={11}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <img src="images/logo.svg" alt="SNGC" width="50%" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" styles={{ fontWeight: 'bolder' }}>SAIF NASSER</Typography>
                            <Typography variant="caption"><small>GENERAL MAINTENANCE LLC</small></Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={11}>
                    <hr />
                    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="body2">Address : <small>P.O Box: 32646, Abu Dhabi, United Arab Emirates</small></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">Tel : <small>971-506722440, 971-506163922</small></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">Fax : <small>02-44 55 199</small></Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2">Mail : <small>info@sngcuae.com</small></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">Mr. Ajay : <small>050 67 22440</small></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">Mr. Balan : <small>050 61 63922</small></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={4}>
                    <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.modalButtons}
                        onClick={() => window.open('tel:02-4454574', "_blank")}
                    >
                        <CallIcon />
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.modalButtons}
                        onClick={() => window.open('mailto:info@sngcuae.com?Subject=Customer%20Query', "_blank")}
                    >
                        <MailIcon />
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.modalButtons}
                        edge="end" onClick={() => window.open('https://www.google.com/maps/dir//24.453884,54.3773438/@24.4199889,54.3935238,13.25z?hl=en', "_blank")}
                    >
                        <RoomIcon />
                    </Button>
                </Grid>
            </Grid>
        </Dialog >

    );
}

export default GetInTouchModal

import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles, Typography } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    navbarButton: {
        boxShadow: 'none',
    },
    navbarButtonText: {
        color: '#FFFFFF',
        fontWeight: 'bold'
    }
}));

const CallToActionButton = props => {
    const classes = useStyles(props);
    const { actionType, text, onClick } = props;
    return (
        <Button variant="contained" size="large" color={actionType} className={classes.navbarButton} onClick={() => onClick()}>
            <Typography variant="button" className={classes.navbarButtonText}>{text}</Typography>
        </Button>
    )
}

CallToActionButton.propTypes = {

}

export default CallToActionButton

import React, { useState } from 'react';
import { Paper, Button, Grid, makeStyles } from '@material-ui/core'

import Carousel, { slidesToShowPlugin, arrowsPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowForwardIcon from '@material-ui/icons/KeyboardArrowRight';


const useStyles = makeStyles((theme) => ({
    arrowKeys: {
        fontSize: 50,
        color: theme.palette.primary.main,
        opacity: 0.2
    },
    carouselContainer: {
        paddingBottom: theme.spacing(5)
    }
}));

const WorkCarouselMob = props => {
    const classes = useStyles(props);

    const projectImages = [1, 2, 3, 4, 5, 6, 7];

    const cutomLeftButton = (
        <Button color="primary" size="large" style={{ borderRadius: 100 }}><ArrowBackIcon className={classes.arrowKeys} /></Button>
    );
    const cutomRighttButton = (
        <Button color="primary" size="large" style={{ borderRadius: 100 }}><ArrowForwardIcon className={classes.arrowKeys} /></Button>
    );

    const arraowPluginConfig = {
        resolve: arrowsPlugin,
        options: {
            arrowLeft: cutomLeftButton,
            arrowLeftDisabled: cutomLeftButton,
            arrowRight: cutomRighttButton,
            arrowRightDisabled: cutomRighttButton,
            addArrowClickHandler: true,
        }
    };

    const autoPlayConfigs = {
        resolve: autoplayPlugin,
        options: {
            interval: 2000,
        }
    };
    return (

        <Grid xs={11} md={10} className={classes.carouselContainer}>

            <Carousel
                plugins={[
                    'centered',
                    'infinite',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: 7
                        }
                    },
                    {
                        resolve: autoplayPlugin,
                        options: {
                            interval: 2000,
                        }
                    },
                    { ...autoPlayConfigs },
                    { ...arraowPluginConfig }
                ]}
                breakpoints={{
                    640: {
                        plugins: [
                            'centered',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 1
                                }
                            },
                            { ...autoPlayConfigs },
                            { ...arraowPluginConfig }
                        ]
                    },
                    900: {
                        plugins: [
                            'centered',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 3
                                }
                            },
                            { ...autoPlayConfigs },
                            { ...arraowPluginConfig }
                        ]
                    },
                    1024: {
                        plugins: [
                            'centered',
                            'infinite',
                            {
                                resolve: slidesToShowPlugin,
                                options: {
                                    numberOfSlides: 4
                                }
                            },
                            { ...autoPlayConfigs },
                            { ...arraowPluginConfig }
                        ]
                    },
                }}
            >
                {
                    projectImages.map(id => {
                        const imageURL = `images/projects/${id}.jpg`;
                        return <img src={imageURL} width="100%" />
                    })
                }
            </Carousel>
        </Grid>
    )
}
export default WorkCarouselMob

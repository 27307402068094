import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import CallToActionButton from '../components/CallToActionButton'
import CustomHeading from '../components/CustomHeading';
import CustomSubHeading from '../components/CustomSubHeading';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(25)
    },
    paragraph: {
        color: '#AAAAAA',
    },
    listItems: {
        fontWeight: 'bold',
        color: theme.palette.secondary.main
    }
}));

const AboutUsContainer = props => {

    const classes = useStyles(props);

    const { setSelectedMenu } = props;
    const selectAboutMenu = () => {
        setSelectedMenu('about');
    }
    return (
        <>
            <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
                <Grid item xs={8}>
                    <Grid container direction="row" justify="center" alignItems="center" >
                        <Grid item xs={12} md={5}>
                            <img src="images/about1.png" width="100%" style={{ borderRadius: 10 }} />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div style={{ flexGrow: 1 }} />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <CustomHeading text="WHO WE ARE" />
                            <br />
                            <CustomSubHeading text="Experience Quality Execution" />
                            <br />
                            <Typography align="justify" variant="caption" className={classes.paragraph}>
                                We are glad to introduce ourselves asIndustrial electrical project and maintenance company based in Abu Dhabi, United Arab Emirates.<br /><br />

                                A team of qualified and experienced personnel excelling in technical aspects of project execution in diverse fields such as instrumentation, mechanical and electrical in particular.<br /><br />

                                All installations have been executed and commissioned with maximum safety standards set by the government authorities and strictly follows DEWA's rules and regulations and will continue to do so in the future projects as well
                            </Typography>
                            <br /><br /><br />
                            <Grid container justify="flex-end" alignItems="center">
                                <CallToActionButton actionType="secondary" text="LEARN MORE" onClick={selectAboutMenu} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
                <Grid item xs={8}>
                    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} md={3}>
                            <CustomHeading text="HOW ITS DONE" />
                            <br />
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <Grid item xs={1}>
                                    <CheckCircleIcon color='primary' />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography align="justify" variant="caption" className={classes.listItems}>
                                        &nbsp;&nbsp;FIELD ANALYSIS
                                    </Typography>
                                </Grid>
                                <br /><br />
                                <Grid item xs={1}>
                                    <CheckCircleIcon color='primary' />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography align="justify" variant="caption" className={classes.listItems}>
                                        &nbsp;&nbsp;EXPERT TECHNICIANS
                                    </Typography>
                                </Grid>
                                <br /><br />
                                <br /><br />
                                <Grid item xs={1}>
                                    <CheckCircleIcon color='primary' />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography align="justify" variant="caption" className={classes.listItems}>
                                        &nbsp;&nbsp;FINAL OUTPUT
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2}> </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container direction="row" justify="flex-end" alignItems="center" >
                                <img src="images/about2.png" width="100%" style={{ borderRadius: 10 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

AboutUsContainer.propTypes = {

}

export default AboutUsContainer

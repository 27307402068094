import React from 'react'
import PropTypes from 'prop-types'
import { Card, Fab, Grid, makeStyles, Typography } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
    containerCard: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        // paddingBottom: props => props.isMobile ? theme.spacing(10) : theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 10,
    },
    imageCard: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 10,
    },
    aboutText: {
        textAlign: 'justify',
        color: theme.palette.secondary.contrastText,
        margin: theme.spacing(2)
    },
    fabButton: {
        margin: theme.spacing(1),
        opacity: 0.3
    },
}));

const AboutPageContainer = props => {
    const classes = useStyles(props);

    return (
        <Grid container direction="row" justify="center" alignItems="center" >
            <Grid item xs={11}>
                <Card className={classes.containerCard}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                        <Grid item xs={12} md={5}>
                            <img src="images/pages/about/card_image.png" width="100%" className={classes.imageCard} />
                        </Grid>
                        <Grid item xs={11} md={6} className={classes.aboutText}>
                            <img src="images/logo_dark.svg" width="20%" /><hr />
                            <Typography variant="caption" >
                                <br /><br />
                                <b style={{ fontSize: 25 }}>W</b>e are glad to introduce ourselves as industrial electrical project and maintenance company based in Abu Dhabi, United Arab Emirates, with a team of qualified and experienced personnel excelling in technical aspects of project execution in diverse fields such as instrumentation, mechanical and electrical in particular. All installations have been executed and commissioned with maximum safety standards set by the government authorities and strictly follows DEWA's rules and regulations and will continue to do so in the future projects as well.<br /><br />

                                Saif Nasser General Maintenance LLC has earned the reputation of being the experienced professionals, who has made their mark in the contracting, sub contracting &amp; maintenance industry for several successful years in United Arab Emirates. We have established major valued clients through our commitment, services &amp; high quality of workmanship.<br /><br />

                                Together we have identified and will continue to nurture the principle ingredient for long term success which is the talent and commitment of our employees.<br /><br />

                                We have firmly established ourselves through trained and motivated workman and experienced supervisory staff. All necessary infrastructural support was earned through credible visionary management. It is of prime importance to us that client satisfaction is ensured at all times. The healthy growth in our accomplishments, the employee strength and revenue stands as testimonial to the success of organization.<br /><br /><br /><br />
                            </Typography>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="center" >
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <FacebookIcon />
                                    </Fab>
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <TwitterIcon />
                                    </Fab>
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <YouTubeIcon />
                                    </Fab>
                                    <Fab size="small" aria-label="like" className={classes.fabButton} >
                                        <InstagramIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                            <br /><br />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

AboutPageContainer.propTypes = {

}

export default AboutPageContainer

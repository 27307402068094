import React from 'react'
import PropTypes from 'prop-types'
import { Card, Divider, Grid } from '@material-ui/core'
import ContactCardItem from '../components/ContactCardItem'

const ContactContainer = props => {
    const { isMobile } = props;
    const cards = [
        { fieldName: 'Find Us', icon: 'find', fieldValue: <>P.O.BOX : 32646,<br />ABU DHABI<br /></>, hyperlink: 'https://www.google.com/maps/dir//24.453884,54.3773438/@24.4199889,54.3935238,13.25z?hl=en' },
        { fieldName: 'Ring Us', icon: 'ring', fieldValue: <><br />02-4454574<br /></>, hyperlink: 'tel:02-4454574' },
        { fieldName: 'Mail Us', icon: 'mail', fieldValue: <><br />info@sngcuae.com<br /></>, hyperlink: 'mailto:info@sngcuae.com?Subject=Customer%20Query' }
    ]
    return (
        isMobile ? (<></>) : (
            <Grid container direction="row" justify="center" alignItems="center" >
                <Grid item xs={8}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                        {cards.map(card => {
                            const { fieldName, icon, fieldValue, hyperlink } = card
                            return (<ContactCardItem key={card.fieldName} fieldName={fieldName} icon={icon} fieldValue={fieldValue} hyperlink={hyperlink} />)
                        })}
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

ContactContainer.propTypes = {

}

export default ContactContainer

import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import CallToActionButton from './CallToActionButton';


const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 10,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
    },
    media: {
        width: '100%',
    },
    callToActionContainer: {
        paddingBottom: theme.spacing(5),
    },
    descriptionContainer: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    serviceNameContainer: {
        paddingTop: theme.spacing(2),
        fontWeight : 'bolder',
    }
}));

const ServicesCards = props => {
    const classes = useStyles();
    const { image, service, description } = props;
    const imageURL = `images/services/${image}.png`;
    return (
        <Grid item xs={11} md={4}>
            <Card className={classes.card}>
                <CardActionArea>
                    <Grid item xs={12} md={12}>
                        <img src={imageURL} width="100%" />
                    </Grid>
                    <CardContent align="center">
                        <Grid item xs={12} md={12}  className={classes.serviceNameContainer}>
                            <Typography gutterBottom variant="button" color="primary" className={classes.serviceNameContainer}>
                                {service}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography variant="body2" color="textSecondary" component="p" align="center" className={classes.descriptionContainer}>
                                {description}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.callToActionContainer}>
                            <CallToActionButton text="LEARN MORE" actionType="secondary" />
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

ServicesCards.propTypes = {

}

export default ServicesCards

import React from 'react'
import PropTypes from 'prop-types'
import CustomHeading from '../components/CustomHeading'
import { Card, Grid, makeStyles, Typography } from '@material-ui/core'
import CustomSubHeading from '../components/CustomSubHeading';
import ServicesCards from '../components/ServicesCards';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(25)
    },
    container: {
        textAlign: 'center',
    },
    cardsContainer: {
        paddingTop: theme.spacing(10)
    }
}));

const ServicesContainer = props => {
    const classes = useStyles(props);
    const services = [
        { image: 'services1', service: "INSTRUMENTATION", description: "Air-conditioning, Ventilations * Air filtration systems installation & maintenance" },
        { image: 'services2', service: "FACILITY MANAGEMENT", description: "Plumbing & sanitary, Painting, Floor & Wall tiling, Carpentry, Partitions, Building cleaning" },
        { image: 'services3', service: "ELECTRICAL & MECHANICAL", description: "Electromechanical equipment installation & maintenance" },
    ]
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
            <Grid item xs={12} md={10}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={10} md={2}>
                        <CustomHeading text="OUR SERVICES" /><br />
                    </Grid>
                    <Grid item xs={10} md={11} className={classes.container}>
                        <CustomSubHeading text="Our amazing team can offer unmatched customer satisfaction" />
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={3} className={classes.cardsContainer}>
                        {services.map(serviceData=>{
                            const {image,service,description} = serviceData;
                            return (<ServicesCards image={image} service={service} description={description}/>)
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

ServicesContainer.propTypes = {

}

export default ServicesContainer

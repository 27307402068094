import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    subHeading: {
        color: theme.palette.secondary.main,
        fontWeight: 'bolder'
    },
}));

const CustomSubHeading = props => {

    const classes = useStyles(props);
    const {text} = props

    return (
        <Typography variant="h5" className={classes.subHeading}>
            {text}
        </Typography>
    )
}

CustomSubHeading.propTypes = {

}

export default CustomSubHeading
